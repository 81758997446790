
import { createStore } from 'vuex'
import * as wt from '../watchtower'
import * as bsh from '../bookshelf'
import * as supabaseClient from '../supabaseClient'

export default createStore({
    state: {

        'CONSTANTS': wt.CONSTANTS,

        'change_title_tag': wt.change_title_tag,

        'toggle_sidebar': wt.toggle_sidebar,

        'show_sidebar': wt.CONSTANTS['SHOW_SIDEBAR'],
        'get_sidebar': wt.get_sidebar,

        'add_class': wt.add_class,
        'remove_class': wt.remove_class,

        'tutorials_array': bsh.CONTENTS['basic_tutorials_list'],
        'workflow_tutorials': bsh.CONTENTS['workflow_tutorials'],

        'set_cookie': wt.set_cookie,
        'get_cookie': wt.get_cookie,
        'if_cookie_exists': wt.if_cookie_exists,

        'supabase': supabaseClient.supabase,
        'supabase_categories': supabaseClient.get_categories,
        'supabase_create': supabaseClient.create_data,
        'supabase_get': supabaseClient.get_data,
        'supabase_read_all': supabaseClient.read_all,
        'supabase_read_paged': supabaseClient.read_paged,
        'supabase_update': supabaseClient.update_data,
        'supabase_delete': supabaseClient.delete_data,
        'supabase_get_mainpage': supabaseClient.get_mainpage,
        'supabase_get_catpagelist': supabaseClient.get_catpagelist,

    },
    getters: {
    },
    mutations: {
    },
    actions: {
    },
    modules: {
    }
})
