
//
//
export const CONTENTS = {
    'basic_tutorials_list': [
        {
            'link': '/p/tutorials/t/how-create-company/',
            'name': 'How to create a company?',
        },
        {
            'link': '/p/tutorials/t/how-edit-company/',
            'name': 'How to edit company information?',
        },
        {
            'link': '/p/tutorials/t/how-delete-company/',
            'name': 'How to delete a company?',
        },
        {
            'link': '/p/tutorials/t/how-create-product/',
            'name': 'How to create a product?',
        },
        {
            'link': '/p/tutorials/t/how-edit-product/',
            'name': 'How to edit product information?',
        },
        {
            'link': '/p/tutorials/t/how-delete-product/',
            'name': 'How to delete a product?',
        },
        {
            'link': '/p/tutorials/t/how-add-pipeline-stage/',
            'name': 'How to add a pipeline stage?',
        },
        {
            'link': '/p/tutorials/t/how-edit-pipeline-stage/',
            'name': 'How to edit a pipeline stage?',
        },
        {
            'link': '/p/tutorials/t/how-remove-pipeline-stage/',
            'name': 'How to remove a pipeline stage?',
        },
        {
            'link': '/p/tutorials/t/how-create-role/',
            'name': 'How to create a new role?',
        },
        {
            'link': '/p/tutorials/t/how-edit-role/',
            'name': 'How to edit role information?',
        },
        {
            'link': '/p/tutorials/t/how-change-role-privilege/',
            'name': 'How to change role privileges?',
        },
        {
            'link': '/p/tutorials/t/how-assign-personnel-role/',
            'name': 'How to assign personnel to a role?',
        },
        {
            'link': '/p/tutorials/t/how-delete-role/',
            'name': 'How to delete role?',
        },
        {
            'link': '/p/tutorials/t/how-request-consultation/',
            'name': 'How to request a consultation [as a client]?',
        },
        {
            'link': '/p/tutorials/t/how-create-consultation/',
            'name': 'How to create a consultation [as an owner/personnel]?',
        },
        {
            'link': '/p/tutorials/t/how-edit-consultation/',
            'name': 'How to edit a consultation?',
        },
        {
            'link': '/p/tutorials/t/how-create-assignment/',
            'name': 'How to create an assignment record?',
        },
        {
            'link': '/p/tutorials/t/how-edit-assignment/',
            'name': 'How to edit assignment information?',
        },
        {
            'link': '/p/tutorials/t/how-delete-assignment/',
            'name': 'How to delete an assignment record?',
        },
        {
            'link': '/p/tutorials/t/how-create-client/',
            'name': 'How to create a client record?',
        },
        {
            'link': '/p/tutorials/t/how-send-invite-client/',
            'name': 'How to send an invite to a client?',
        },
        {
            'link': '/p/tutorials/t/how-edit-client/',
            'name': 'How to edit client information?',
        },
        {
            'link': '/p/tutorials/t/how-delete-client/',
            'name': 'How to delete a client record?',
        },
        {
            'link': '/p/tutorials/t/how-create-handbook/',
            'name': 'How to create a handbook?',
        },
        {
            'link': '/p/tutorials/t/how-add-pages-handbook/',
            'name': 'How to add pages to a handbook?',
        },
        {
            'link': '/p/tutorials/t/how-edit-handbook/',
            'name': 'How to edit handbook information?',
        },
        {
            'link': '/p/tutorials/t/how-delete-handbook/',
            'name': 'How to delete a handbook?',
        },
        {
            'link': '/p/tutorials/t/how-create-personnel/',
            'name': 'How to create a personnel record?',
        },
        {
            'link': '/p/tutorials/t/how-send-invite-personnel/',
            'name': 'How to send an invite to a personnel?',
        },
        {
            'link': '/p/tutorials/t/how-edit-personnel/',
            'name': 'How to edit personnel information?',
        },
        {
            'link': '/p/tutorials/t/how-delete-personnel/',
            'name': 'How to delete a personnel record?',
        },
    ],
    'basic_tutorials': {
        'how-create-company': {
            'title':'How to create a company?',
            'content-type': 'step-by-step',
            'steps': [
                {
                    'procedure': '1. Go to Dashboard',
                    'img': '/img/screens/dashboard1-e.png',
                },
                {
                    'procedure': `2. Click the "<strong>Setup your first company</strong>", if you don't have a company yet.`,
                    'img': '/img/screens/dashboard1a-e.png',
                },
                {
                    'procedure': `3. Or click the "<strong>Add Company</strong>" button if you already have a company.`,
                    'img': '/img/screens/dashboard1c-e.png',
                },
                {
                    'procedure': `4. A pop-up will appear, fill out the information. Then press "<Strong>Submit</strong>"`,
                    'img': '/img/screens/add-company-pop-up-e.png',
                },
            ],
        },
        'how-edit-company': {
            'title':'How to edit your company information?',
            'content-type': 'step-by-step',
            'steps': [
                {
                    'procedure': '1. Go to Dashboard',
                    'img': '/img/screens/dashboard1-e.png',
                },
            ],
        },
    },
    'workflow_tutorials': {
        'how-to-create-workflow': {
            'title':'How to create a workflow?',
            'content-type': 'step-by-step',
            'steps': [
                {
                    'procedure': 'Click "Workflows" on the sidebar.',
                    'img': '#',
                },
                {
                    'procedure': 'Click "Add" button.',
                    'img': '#',
                },
                {
                    'procedure': 'Change workflow title.',
                    'img': '#',
                },
                {
                    'procedure': 'Add trigger.',
                    'img': '#',
                },
                {
                    'procedure': 'Add modules.',
                    'img': '#',
                },
                {
                    'procedure': 'Click Save.',
                    'img': '#',
                },
            ],
        },
        'what-is-a-trigger': {
            'title': 'What is a trigger?',
            'content-type': 'text',
            'content': "A trigger is the very first object that you will have on your workflow. It watches for certain conditions or events before running the whole workflow. Some events occurs internal while others wait for external data. e.g. Webhook",
            'image': '#',

        },
        'what-is-a-module': {
            'title': 'What is a module?',
            'content-type': 'text',
            'content': "Modules are objects that you add to your workflow to make it perform certain functions from within your account. You can also do certain actions like sending data externally. e.g. HTTP",
        },
        'how-to-select-data-from-another-module': {
            'title': 'How to select data from another module?',
            'content-type': 'step-by-step',
            'steps': [
                {
                    'procedure': '1. Click on a module. A pop-up will appear.',
                    'img': '#',
                },
                {
                    'procedure': '2. Click on a field. Another pop-up will appear.',
                    'img': '#',
                },
                {
                    'procedure': '3. Click on the dropdown on the module from the data selection.',
                    'img': '#',
                },
                {
                    'procedure': '4. Then click on the data label to add itt to the current module.',
                    'img': '#',
                },
            ],
        },
        'how-to-change-trigger-module': {
            'title': 'How to change trigger/module?',
            'content-type': 'step-by-step',
            'steps': [
                {
                    'procedure': '1. Click on the trigger. A pop-up will appear.',
                    'img': '#',
                },
                {
                    'procedure': '2. Click on the upper right button with a left arrow icon.',
                    'img': '#',
                },
                {
                    'procedure': '3. Then select the trigger you want to replace.',
                    'img': '#',
                },
            ],
        },
        'how-to-delete-module': {
            'title': 'How to delete module?',
            'content-type': 'step-by-step',
            'steps': [
                {
                    'procedure': '1. Right click on the module.',
                    'img': '#',
                },
                {
                    'procedure': '1. Then click "Delete module".',
                    'img': '#',
                },
            ],
        },
        'how-to-add-router-node': {
            'title': 'How to add router node?',
            'content-type': 'step-by-step',
            'steps': [
                {
                    'procedure': '1. Click on the router module. (do not drag)',
                    'img': '#',
                },
                {
                    'procedure': '2. A new node has been added.',
                    'img': '#',
                },
            ],
        },
        'how-to-change-background-text-color': {
            'title': 'How to change background color?',
            'content-type': 'step-by-step',
            'steps': [
                {
                    'procedure': '1. To change the workflow background, click on Settings button on the upper right corner.',
                    'img': '#',
                },
                {
                    'procedure': '2. From the dropdown, change the background color.',
                    'img': '#',
                },
                {
                    'procedure': '3. To change the module colors, click on the module or trigger.',
                    'img': '#',
                },
                {
                    'procedure': '4. From the pop-up, click the settings tab.',
                    'img': '#',
                },
                {
                    'procedure': '5. Then change the colors.',
                    'img': '#',
                },
            ],
        },
        'how-to-auto-arrange-modules': {
            'title': 'How to auto arrange modules?',
            'content-type': 'step-by-step',
            'steps': [
                {
                    'procedure': '1. Add modules to your workflow.',
                    'img': '#',
                },{
                    'procedure': '2. Then click the Re-arrange button on the upper right corner.',
                    'img': '#',
                },
            ],
        },
        'how-to-delete-workflow': {
            'title': 'How to delete workflow?',
            'content-type': 'step-by-step',
            'steps': [
                {
                    'procedure': '1. First method to delete a workflow, click Workflows on the sidebar.',
                    'img': '#',
                },
                {
                    'procedure': '2. Click the dropdown icon beside the workflow title.',
                    'img': '#',
                },
                {
                    'procedure': '3. Then click Delete.',
                    'img': '#',
                },
                {
                    'procedure': '4. Second method to delete a workflow, open the workflow.',
                    'img': '#',
                },
                {
                    'procedure': '5. Click the Settings button on the upper right corner.',
                    'img': '#',
                },
                {
                    'procedure': '6. Click Delete Workflow button from the dropdown.',
                    'img': '#',
                },
            ],
        },
        'how-to-grid-on': {
            'title': 'How to turn grid on?',
            'content-type': 'step-by-step',
            'steps': [
                {
                    'procedure': '1. Open the workflow.',
                    'img': '#',
                },
                {
                    'procedure': '2. Click the Settings button on the upper right corner.',
                    'img': '#',
                },
                {
                    'procedure': '3. Toggle the grid from the settings dropdown.',
                    'img': '#',
                },
            ],
        },
        'how-to-change-grid-size': {
            'title': 'How to change grid size?',
            'content-type': 'step-by-step',
            'steps': [
                {
                    'procedure': '1. Open the workflow.',
                    'img': '#',
                },
                {
                    'procedure': '2. Click the Settings button on the upper right corner.',
                    'img': '#',
                },
                {
                    'procedure': '3. Change the grid and colors from the settings dropdown.',
                    'img': '#',
                },
            ],
        },
    },
    
}






