import { createRouter, createWebHistory } from 'vue-router'

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import('@/views/homepage.vue')
    },
    {
        path: '/:cslug1:/:slug1:/',
        name: 'main_pages',
        component: () => import('@/views/page_template.vue'),
        children: [
            {
                path: '/:cslug1:/:slug1:/', 
                name: 'main_subpage', 
                components: {
                    inner: () => import('../views/page_template.vue'),
                }
            },
            {
                path: '/:cslug1:/:slug1:/:cslug2:/:slug2:/', 
                name: 'sub_subpage', 
                components: {
                    inner: () => import('../views/page_template.vue'),
                }
            },
        ],
    },
    
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
