
/*
*
* https://github.com/xriley/CoderDocs-Theme
* https://themes.3rdwavemedia.com/demo/bs5/coderdocs/index.html
*
*
*/

//
//
export const CONSTANTS = {

    'DEBUG_MODE': false,

    'APP_NAME': 'Hierarch.io',
    'APP_SLOGAN': 'Help Center, Documentations, etc.',
    'APP_DESCRIPTION': 'The place to search for help.',
    'THEME': 'CoderDocs-Theme',

    'API_DOMAIN':  'https://api.hierarch.io',

    'SHOW_SIDEBAR': true,

}

//
//
export const change_title_tag = function(v){
    document.title = v + ' | ' + CONSTANTS['APP_NAME']
}

//
//
export const toggle_sidebar = function(){
    CONSTANTS['SHOW_SIDEBAR'] = !CONSTANTS['SHOW_SIDEBAR']
    console.log(CONSTANTS['SHOW_SIDEBAR'])
    set_cookie(
        'sidebar_visibility',
        CONSTANTS['SHOW_SIDEBAR'],
        1000
    )
}

//
//
export const get_sidebar = function(){
    //console.log(CONSTANTS['SHOW_SIDEBAR'])
    return CONSTANTS['SHOW_SIDEBAR']
}

//
//
export const add_class = function(_id,_class){

    let el = document.getElementById(_id);
    el.classList.add(_class)

}

//
//
export const remove_class = function(_id,_class){

    let el = document.getElementById(_id);
    el.classList.remove(_class)

}

//
//
export const set_cookie = function(cname, cvalue, expiry_days) {
    const d = new Date();
    d.setTime(d.getTime() + (expiry_days * 24 * 60 * 60 * 1000));
    let expires = "expires="+d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

//
//
export const get_cookie = function(cname){

    // returns value of cookie['']

    let name = cname + "=";
    let ca = document.cookie.split(';');
    for(let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1)
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length)
        }
    }
    return ""
}
//
//
export const if_cookie_exists = function(cname){

    // returns true or false

    let the_cookie = get_cookie(cname);
    if (the_cookie != "") {
        return true
    } else {
        return false
    }
}



