
//
//
import { createClient } from '@supabase/supabase-js'

//
//
export const supabase = createClient(

    //
    //
    'https://bwcznegvbxdicuhhmspp.supabase.co', 

    //
    //
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImJ3Y3puZWd2YnhkaWN1aGhtc3BwIiwicm9sZSI6ImFub24iLCJpYXQiOjE3Mjc2MDI3NjMsImV4cCI6MjA0MzE3ODc2M30.cvpxl7T4yqb9lugru1r9sY8NbsfJXMeR9m2u2Twaexk',

)

// DONT FORGET TO SET THIS AS ENVIRONMENT VARIABLE
// THIS IS UNSAFE

/*
*
*
*
*
*
*/
export const create_data = async function(kwargs){

    //
    //
    let response = {}
    console.log(kwargs)
    //
    //
    let { data, error } = await supabase
        .from(kwargs['table'])
        .insert([
            kwargs['values'], // { col1: 'value1', col2: 'value2', }
        ])
        .select()

    //
    //
    response = {
        'data': data,
        'error': error,
    }

    //
    //
    return response

}

/*
*
*
*
*
*
*/
export const get_mainpage = async function(kwargs){

    //
    //
    let response = {}

    //
    //
    let { data, error } = await supabase
        .from('pages')
        .select('*, categories(id,slug)')
        .eq('categories.slug', kwargs['category_slug'])
        .eq('slug', kwargs['page_slug'])
        .maybeSingle()

    //
    //
    response = {
        'data': data,
        'error': error,
    }

    //
    //
    return response

}

/*
*
*
*
*
*
*/
export const get_catpagelist = async function(kwargs){

    //
    //
    let response = {}

    //
    //
    let { data, error } = await supabase
        .from('pages')
        .select('id,status,slug,title,category, categories(id,slug)')
        .eq('category', kwargs['category_id'])
        .order('order', { ascending: true })
    
    //
    //
    response = {
        'data': data,
        'error': error,
    }

    //
    //
    return response

}

/*
*
*
*
*
*
*/
export const get_data = async function(kwargs){

    //
    //
    let response = {}

    //
    //
    let { data, error } = await supabase
        .from(kwargs['table'])
        .select()
        .eq(kwargs['column'], kwargs['value'])
        .maybeSingle()

    //
    //
    response = {
        'data': data,
        'error': error,
    }

    //
    //
    return response

}

/*
*
*
*
*
*
*/
export const read_all = async function(kwargs){
    
    //
    //
    let response = {}
    let _columns = '*'

    //
    //
    if(kwargs['columns'] != undefined){

        //
        //
        if(kwargs['columns'] != false){
            _columns = kwargs['columns']
        }

    }

    //
    //
    let { data, error } = await supabase
        .from(kwargs['table'])
        .select(_columns)
        .order('id', { ascending: true })
    
    //
    //
    response = {
        'data': data,
        'error': error,
    }

    //
    //
    return response

}

/*
*
*
*
*
*
*/
export const read_paged = async function(kwargs){

    //
    //
    let response = {}
    let _columns = '*'
    // let _page = kwargs['page']
    // let _count = kwargs['count']
    let _start = 0
    let _end = 9

    //
    //
    if(kwargs['columns'] != undefined){

        //
        //
        if(kwargs['columns'] != false){
            _columns = kwargs['columns']
        }

    }

    //
    //
    if(kwargs['page'] != undefined){

        //
        //
        _start = kwargs['page'] - 1
        
        //
        //
        if(_start < 0){ _start = 0 } // default 0
    }

    //
    //
    if(kwargs['page'] != undefined){

        //
        //
        if(kwargs['count'] != undefined){
            _end = _start + kwargs['count'] // default 9
        }

    }

    //
    //
    let { data, error } = await supabase
        .from(kwargs['table'])
        .select(_columns)
        .range(_start, _end)
        .order('id', { ascending: true })
    
    //
    //
    response = {
        'data': data,
        'error': error,
    }

    //
    //
    return response

}

/*
*
*
*
*
*
*/
export const update_data = async function(kwargs){

    //
    //
    let response = {}

    //
    //
    let { data, error } = await supabase
        .from(kwargs['table'])
        .update(kwargs['new_values']) // { other_column: 'otherValue' }
        .eq(kwargs['column'], kwargs['value'])
        .select()

    //
    //
    response = {
        'data': data,
        'error': error,
    }

    //
    //
    return response    

}

/*
*
*
*
*
*
*/
export const delete_data = async function(kwargs){

    //
    //
    let response = {}

    //
    //
    const { error } = await supabase
        .from(kwargs['table'])
        .delete()
        .eq(kwargs['column'], kwargs['value'])

    //
    //
    response = {
        'data': 'deleted',
        'error': error,
    }

    //
    //
    return response

}








